<template>
  <el-container class="app-wrapper">
    <sidebar ref="sidebar" />
    <div v-if="!sideBar" @click="handleCloseClick" class="mask-layer"></div>
    <div class="main-container">
      <navbar />
      <router-view :key="key" />
    </div>
  </el-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { getAuth } from '@/server/api/login';
import { getMessage } from '@/server/api/system';
export default {
  name: "Layout",
  props: {},
  components: {
    sidebar: () => import("@/components/layout/sidebar.vue"),
    navbar: () => import("@/components/layout/navbar.vue")
  },
  data () {
    return {
    }
  },
  // 计算属性
  computed: {
    ...mapState(['sideBar']),
    ...mapGetters(['userInfo']),
    key () {
      return this.$route.path
    },
  },
  // 创建后
  created () {
    if (localStorage.getItem('token')) {
      this.getAuthInfo().then(() => {
        if (!document.getElementById('watermark')) {
          this.initWatermark();
        }
      });
      this.getMessageData();
    }
  },
  // 挂载后
  mounted () {
  },
  // 方法
  methods: {
    // 获取用户信息
    async getAuthInfo () {
      const res = await getAuth();
      console.log(res, '=== res auth ===');
      if (res.code == 200 && Object.keys(res.data).length) {
        const { user = {}, menu = [], buttonArray = [] } = res.data;
        localStorage.setItem('user', JSON.stringify(user));
        this.$store.dispatch("setUser", user);
        localStorage.setItem('menu', JSON.stringify(menu));
        this.$store.dispatch("setMenu", menu);
        localStorage.setItem('permission', JSON.stringify(buttonArray));
        this.$store.dispatch("setPerms", buttonArray);
      }
    },
    // 获取新消息
    async getMessageData () {
      const res = await getMessage({ status: 0 });
      if (res.code == 200 && res.data && Object.keys(res.data).length) {
        this.$store.dispatch("setNoticeCount", res.data.length);
      }
    },
    // 小屏幕下点击遮罩层关闭菜单层
    handleCloseClick () {
      const sidebar = this.$refs.sidebar;
      if (sidebar) {
        sidebar.handleCloseClick();
      }
    },
    // 生成水印
    initWatermark () {
      const { name = '', username = '' } = this.userInfo;
      if (!(name && username)) { return false }
      // 创建一个canvas
      const canvas = document.createElement('canvas');
      // 设置画布的宽高
      canvas.width = 280;
      canvas.height = 220;
      // 获取画笔
      const ctx = canvas.getContext('2d');
      // 设置文本样式  
      ctx.font = '16px Arial'; // 字体大小和字体
      ctx.rotate(0.4);// 设置文字旋转角度
      ctx.fillStyle = 'rgba(0,0,0,0.3)'; // 文本颜色
      // 绘制文本  
      ctx.fillText(`${name}${username}`, canvas.width / 14, canvas.height / 14); // 文本内容和位置
      ctx.fillText(`${name}${username}`, canvas.width - canvas.width / 2, canvas.height / 2.2); // 文本内容和位置
      // 将画布转成图片
      const img = canvas.toDataURL();
      const divEl = document.createElement('div');
      divEl.id = 'watermark';
      divEl.style = `pointer-events:none;position:fixed;width:100%;height:100%;top:0px;left:0px;opacity:0.2;z-index:990;background: url(${img}) 0 0 repeat;`;
      document.body.append(divEl);
    }
  },
  // 组件销毁
  beforeDestroy () {
  },
  // 监听
  watch: {
  },
}
</script>
 
<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.main-container {
  flex: 1;
  width: 500px;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.fixed-comment-inlet {
  position: fixed;
  right: 32px;
  bottom: 32px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #e9e9eb;
  border-radius: 8px;
  width: 50px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  li {
    color: #333;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 6px;
    cursor: pointer;
    overflow: hidden;
    &:nth-child(1) {
      background: #409eff;
      color: #fff;
      &:hover {
        background: #409eff;
        color: #fff;
      }
    }
    &:hover {
      background-color: #f4f4f5;
      color: #409eff;
    }
  }
}
</style>