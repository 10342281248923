import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { Message } from "element-ui";

import Layout from '@/views/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'Test',
    component: ()=> import('@/views/test'),
  },
  // 登录
  {
    path: '/',
    name: '',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('@/views/login/index'),
  },
  // 账户信息
  {
    path: '/',
    component: Layout,
    redirect: '/accountInfo',
    alwaysShow: true,
    children: [
      {
        path: 'accountInfo',
        name: 'AccountInfo',
        component: () => import('@/views/accountInfo/index'),
        meta: { title: '账户信息', icon: 'el-icon-user', affix: true }
      }
    ]
  },
  // 通知消息
  {
    path: '/message',
    component: Layout,
    redirect: '/message/index',
    alwaysShow: true,
    children: [
      {
        path: '/message/index',
        name: 'MessageIndex',
        component: () => import('@/views/message/index'),
        meta: { title: '消息中心', icon: 'el-icon-message-solid', affix: true }
      }
    ]
  },
  // 首页
  {
    path: '/',
    redirect: '/home',
    component: Layout,
    children:[
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', icon: 'el-icon-home'}
      }
    ]
  },
  // 系统管理
  {
    path:'/system',
    name:'system',
    redirect: '/system/role',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '系统管理',
      icon: 'el-icon-xitongguanli8',
    },
    children: [
      {
        path: '/system/menu',
        name: 'menu',
        component: () => import('@/views/system/menu'),
        meta: { title: '菜单管理', icon: 'el-icon-caidan'}
      },
      {
        path: '/system/role',
        name: 'role',
        component: () => import('@/views/system/role'),
        meta: { title: '角色管理', icon: 'el-icon-role'}
      },
      {
        path: '/system/log',
        name: 'log',
        component: () => import('@/views/system/log'),
        meta: { title: '访问日志', icon: 'el-icon-DCSrizhi'}
      }
    ]
  },
  // 基础数据
  {
    path:'/basic',
    name:'basic',
    redirect: '/basic/role',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '基础数据',
      icon: 'el-icon-document_settings',
    },
    children: [
      {
        path: '/basic/entity',
        name: 'entity',
        component: () => import('@/views/basic/entity'),
        meta: { title: '企业主体管理', icon: 'el-icon-qiyezhuti'}
      },
      {
        path: '/basic/flow_type',
        name: 'flowType',
        component: () => import('@/views/basic/flowType'),
        meta: { title: '审批类型管理', icon: 'el-icon-shenpileixingguanli'}
      },
      {
        path: '/basic/flow',
        name: 'flow',
        component: () => import('@/views/basic/flow'),
        meta: { title: '审批流程管理', icon: 'el-icon-flow'}
      },
      {
        path: '/basic/expense_cate',
        name: 'expenseCate',
        component: () => import('@/views/basic/expenseCate'),
        meta: { title: '报销类型管理', icon: 'el-icon-daijiesuan'}
      },
      {
        path: '/basic/cost_cate',
        name: 'costCate',
        component: () => import('@/views/basic/costCate'),
        meta: { title: '费用类型管理', icon: 'el-icon-feiyongleixing'}
      },
      {
        path: '/basic/payment',
        name: 'payment',
        component: () => import('@/views/basic/payment'),
        meta: { title: '付款类型管理', icon: 'el-icon-fukuanleixingdingyi'}
      },
      {
        path: '/basic/expert',
        name: 'expert',
        component: () => import('@/views/basic/expert'),
        meta: { title: '专家管理', icon: 'el-icon-kehu2'}
      },
      {
        path: '/basic/supplier',
        name: 'supplier',
        component: () => import('@/views/basic/supplier'),
        meta: { title: '供货商管理', icon: 'el-icon-gonghuoshang'}
      },
      {
        path: '/basic/people',
        name: 'people',
        component: () => import('@/views/basic/people'),
        meta: { title: '打款个人管理', icon: 'el-icon-userinfo1'}
      },
      {
        path: '/basic/study_type',
        name: 'studyType',
        component: () => import('@/views/basic/studyType'),
        meta: { title: '学习类型管理', icon: 'el-icon-xuexileixingguanli'}
      },
      {
        path: '/basic/subject',
        name: 'subject',
        component: () => import('@/views/basic/subject'),
        meta: { title: '学科管理', icon: 'el-icon-xuekeguanli'}
      },
      {
        path: '/basic/phase',
        name: 'phase',
        component: () => import('@/views/basic/phase'),
        meta: { title: '学段管理', icon: 'el-icon-xueduanguanli'}
      },
      {
        path: '/basic/highlight',
        name: 'highlight',
        component: () => import('@/views/basic/highlight'),
        meta: { title: '亮点要素管理', icon: 'el-icon-highlight'}
      },
      {
        path: '/basic/lessonType',
        name: 'lessonType',
        component: () => import('@/views/basic/lessonType'),
        meta: { title: '课程类型管理', icon: 'el-icon-kechengleixing'}
      },
      {
        path: '/basic/videoType',
        name: 'videoType',
        component: () => import('@/views/basic/videoType'),
        meta: { title: '视频类型管理', icon: 'el-icon-video-type'}
      },
      {
        path: '/basic/practiceLabel',
        name: 'practiceLabel',
        component: () => import('@/views/basic/practiceLabel'),
        meta: { title: '课堂案例标签管理', icon: 'el-icon-price-tag'}
      },
      {
        path: '/basic/diversifiedLabel',
        name: 'diversifiedLabel',
        component: () => import('@/views/basic/diversifiedLabel'),
        meta: { title: '学习案例标签管理', icon: 'el-icon-price-tag'}
      },
      {
        path: '/basic/toolUnitType',
        name: 'toolUnitType',
        component: () => import('@/views/basic/toolUnitType'),
        meta: { title: '单元设计模板类型管理', icon: 'el-icon-yuyuediandanbeifen'}
      },
      {
        path: '/basic/toolLessonsType',
        name: 'toolLessonsType',
        component: () => import('@/views/basic/toolLessonsType'),
        meta: { title: '课时设计模板类型管理', icon: 'el-icon-ui'}
      },
      {
        path: '/basic/toolEvalType',
        name: 'toolEvalType',
        component: () => import('@/views/basic/toolEvalType'),
        meta: { title: '评课工具类型管理', icon: 'el-icon-icon_gonggongfuwu-chengkaochengjichaxun'}
      },
    ]
  },
  // 人力资源
  {
    path:'/manpower',
    name:'manpower',
    redirect: '/manpower/department',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '人力资源',
      icon: 'el-icon-renliziyuan',
    },
    children: [
      {
        path: '/manpower/department',
        name: 'department',
        component: () => import('@/views/manpower/department'),
        meta: { title: '部门架构', icon: 'el-icon-hierarchy'}
      },
      {
        path: '/manpower/user',
        name: 'user',
        component: () => import('@/views/manpower/user'),
        meta: { title: '企业员工', icon: 'el-icon-qiyeyuangong'}
      },
    ]
  },
  // 办公审批（申请）
  {
    path:'/approve',
    name:'approve',
    redirect: '/approve/reimburse',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '办公审批',
      icon: 'el-icon-bangong-shenpi',
    },
    children:[
      // 报销申请
      {
        path: '/approve/reimburse',
        name: 'reimburse',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/reimburse/initiated',
        meta: { title: '报销申请', icon: 'el-icon-baoxiao1'},
        children:[
          // 我发起的
          {
            path: '/approve/reimburse/initiated',
            name: 'refundInitiated',
            component: () => import('@/views/approve/reimburse/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/reimburse/processed',
            name: 'refundProcessed',
            component: () => import('@/views/approve/reimburse/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 发票申请
      {
        path: '/approve/invoice',
        name: 'invoice',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/invoice/initiated',
        meta: { title: '发票申请', icon: 'el-icon-fapiaoguanli'},
        children:[
          // 我发起的
          {
            path: '/approve/invoice/initiated',
            name: 'refundInitiated',
            component: () => import('@/views/approve/invoice/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/invoice/processed',
            name: 'refundProcessed',
            component: () => import('@/views/approve/invoice/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 借款申请
      {
        path: '/approve/borrow',
        name: 'borrow',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/borrow/initiated',
        meta: { title: '借款申请', icon: 'el-icon-jiekuandan'},
        children:[
          // 我发起的
          {
            path: '/approve/borrow/initiated',
            name: 'borrowInitiated',
            component: () => import('@/views/approve/borrow/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/borrow/processed',
            name: 'borrowProcessed',
            component: () => import('@/views/approve/borrow/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 付款申请
      {
        path: '/approve/payment',
        name: 'payment',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/payment/initiated',
        meta: { title: '付款申请', icon: 'el-icon-fukuanguanli'},
        children:[
          // 我发起的
          {
            path: '/approve/payment/initiated',
            name: 'paymentInitiated',
            component: () => import('@/views/approve/payment/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/payment/processed',
            name: 'paymentProcessed',
            component: () => import('@/views/approve/payment/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 还款申请
      {
        path: '/approve/refund',
        name: 'refund',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/refund/initiated',
        meta: { title: '还款申请', icon: 'el-icon-huankuanguanli'},
        children:[
          // 我发起的
          {
            path: '/approve/refund/initiated',
            name: 'refundInitiated',
            component: () => import('@/views/approve/refund/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/refund/processed',
            name: 'refundProcessed',
            component: () => import('@/views/approve/refund/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 合同申请
      {
        path: '/approve/contract',
        name: 'contract',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/contract/initiated',
        meta: { title: '合同申请', icon: 'el-icon-hetong1'},
        children:[
          // 我发起的
          {
            path: '/approve/contract/initiated',
            name: 'refundInitiated',
            component: () => import('@/views/approve/contract/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/contract/processed',
            name: 'refundProcessed',
            component: () => import('@/views/approve/contract/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 课表申请
      {
        path: '/approve/activity',
        name: 'activity',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/activity/initiated',
        meta: { title: '课表申请', icon: 'el-icon-paiqibiaoge'},
        children:[
          // 我发起的
          {
            path: '/approve/activity/initiated',
            name: 'refundInitiated',
            component: () => import('@/views/approve/activity/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/activity/processed',
            name: 'refundProcessed',
            component: () => import('@/views/approve/activity/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]

      },
    ]
  },
  // 财务管理
  {
    path:'/finance',
    name:'finance',
    redirect: '/finance/invoice',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '财务管理',
      icon: 'el-icon-caiwu',
    },
    children: [
      {
        path: '/finance/invoice',
        name: 'invoice',
        component: () => import('@/views/finance/invoice'),
        meta: { title: '开票管理', icon: 'el-icon-fapiao1'}
      },
      {
        path: '/finance/income',
        name: 'income',
        component: () => import('@/views/finance/income'),
        meta: { title: '到账管理', icon: 'el-icon-daozhangguanli'}
      },
      {
        path: '/finance/remit',
        name: 'remit',
        component: () => import('@/views/finance/remit'),
        meta: { title: '打款管理', icon: 'el-icon-dakuanjilu'}
      },
    ]
  },
  // 客户管理
  {
    path:'/customer',
    name:'customer',
    redirect: '/customer/index',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '客户管理',
      icon: 'el-icon-kehu',
    },
    children: [
      {
        path: '/customer/grade',
        name: 'customerGrade',
        component: () => import('@/views/customer/grade'),
        meta: { title: '客户等级', icon: 'el-icon-zhiji'}
      },
      {
        path: '/customer/index',
        name: 'customerList',
        component: () => import('@/views/customer/index'),
        meta: { title: '客户列表', icon: 'el-icon-kehu3'}
      },
      // {
      //   path: '/customer/contact',
      //   name: 'customerContact',
      //   component: () => import('@/views/customer/contact'),
      //   meta: { title: '客户联系人', icon: 'el-icon-kehulianxiren'}
      // },
    ]
  },
  // 合同管理
  {
    path: '/contract',
    redirect: '/contract/index',
    component: Layout,
    children:[
      {
        path: '/contract/index',
        name: 'contractIndex',
        component: () => import('@/views/contract/index'),
        meta: { title: '合同管理', icon: 'el-icon-hetongguanli'}
      }
    ]
  },
  // 项目管理
  {
    path:'/project',
    name:'project',
    redirect: '/project/index',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '项目管理',
      icon: 'el-icon-xiangmuguanli',
    },
    children: [
      {
        path: '/project/index',
        name: 'projectList',
        component: () => import('@/views/project/index'),
        meta: { title: '项目列表', icon: 'el-icon-xiangmuliebiao1'}
      },
      // 执行前
      {
        path: '/project/before/plan',
        name: 'projectBeforePlan',
        component: () => import('@/views/project/plan'),
        meta: { title: '执行方案'}
      },
      {
        path: '/project/before/solution',
        name: 'projectBeforeSolution',
        component: () => import('@/views/project/solution'),
        meta: { title: '整体解决方案'}
      },
      // 执行中
      {
        path: '/project/running/event',
        name: 'projectRunningEvent',
        component: () => import('@/views/project/event'),
        meta: { title: '项目事件', icon: 'el-icon-xiangmuliebiao'}
      },
      {
        path: '/project/running/schedule',
        name: 'projectRunningSchedule',
        component: () => import('@/views/project/schedule'),
        meta: { title: '执行课表'}
      },
      {
        path: '/project/running/material',
        name: 'projectRunningMaterial',
        component: () => import('@/views/project/material'),
        meta: { title: '过程材料'}
      },
      {
        path: '/project/running/eval',
        name: 'projectRunningEval',
        component: () => import('@/views/project/eval'),
        meta: { title: '在线测评材料'}
      },
      // 执行后
      {
        path: '/project/finish/politician',
        name: 'projectFinishPolitician',
        component: () => import('@/views/project/politician'),
        meta: { title: '政审材料'}
      },
      {
        path: '/project/finish/report',
        name: 'projectFinishReport',
        component: () => import('@/views/project/sumReport'),
        meta: { title: '总结报告'}
      },
      {
        path: '/project/finish/result',
        name: 'projectFinishResult',
        component: () => import('@/views/project/result'),
        meta: { title: '优质成果'}
      },
      {
        path: '/project/finish/news',
        name: 'projectFinishNews',
        component: () => import('@/views/project/news'),
        meta: { title: '成果报道'}
      }
    ]
  },
  // 学习管理
  {
    path:'/study',
    name:'study',
    redirect: '/study/index',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '学习管理',
      icon: 'el-icon-xuexiguanli',
    },
    children: [
      {
        path: '/study/index',
        name: 'projectList',
        component: () => import('@/views/study/index'),
        meta: { title: '学校任务', icon: 'el-icon-xuexirenwu'}
      },
      {
        path: '/study/learn',
        name: 'learning',
        component: () => import('@/views/study/learning'),
        meta: { title: '学习心得', icon: 'el-icon-xuexixinde'}
      },
    ]
  },
  // 系统公告
  {
    path: '/notice',
    redirect: '/notice/index',
    component: Layout,
    children:[
      {
        path: '/notice/index',
        name: 'NoticeIndex',
        component: () => import('@/views/notice/index'),
        meta: { title: '系统公告', icon: 'el-icon-bell'}
      }
    ]
  },
  // 辅助工具资源管理
  {
    path:'/utilman',
    name:'utilman',
    redirect: '/utilman/index',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '资源管理',
      icon: 'el-icon-ziyuanguanli',
    },
    children: [
      // 典型案例
      {
        path: '/utilman/cases/unit',
        name: 'casesUnit',
        component: () => import('@/views/utilman/cases/unit'),
        meta: { title: '单元设计案例管理'}
      },
      {
        path: '/utilman/cases/xtaught',
        name: 'casesXtaught',
        component: () => import('@/views/utilman/cases/xtaught'),
        meta: { title: '思维教学设计管理'}
      },
      {
        path: '/utilman/cases/video',
        name: 'casesVideo',
        component: () => import('@/views/utilman/cases/video'),
        meta: { title: '视频课例管理'}
      },
      {
        path: '/utilman/cases/paper',
        name: 'casesPaper',
        component: () => import('@/views/utilman/cases/paper'),
        meta: { title: '论文案例管理'}
      },
      {
        path: '/utilman/cases/practice',
        name: 'casesCourse',
        component: () => import('@/views/utilman/cases/practice'),
        meta: { title: '课堂实践案例管理'}
      },
      {
        path: '/utilman/cases/diversified',
        name: 'casesDiversified',
        component: () => import('@/views/utilman/cases/diversified'),
        meta: { title: '多样化学习案例管理'}
      },
      // 工具模板
      {
        path: '/utilman/tool/unit',
        name: 'toolUnit',
        component: () => import('@/views/utilman/tool/unit'),
        meta: { title: '单元设计模板管理'}
      },
      {
        path: '/utilman/tool/lessons',
        name: 'toolLessons',
        component: () => import('@/views/utilman/tool/lessons'),
        meta: { title: '课时设计模板管理'}
      },
      {
        path: '/utilman/tool/eval',
        name: 'toolEval',
        component: () => import('@/views/utilman/tool/eval'),
        meta: { title: '教学评课工具管理'}
      },
      {
        path: '/utilman/tool/talk',
        name: 'toolTalk',
        component: () => import('@/views/utilman/tool/talk'),
        meta: { title: '说课模板管理'}
      },
      {
        path: '/utilman/tool/sustain',
        name: 'toolSustain',
        component: () => import('@/views/utilman/tool/sustain'),
        meta: { title: '学习支持模板管理'}
      },
      {
        path: '/utilman/tool/work',
        name: 'toolWork',
        component: () => import('@/views/utilman/tool/work'),
        meta: { title: '作业设计模板管理'}
      },
    ]
  },
  // 知识库管理
  // {
  //   path:'/knowledge',
  //   name:'knowledge',
  //   redirect: '/knowledge/index',
  //   component: Layout,
  //   alwaysShow: true,
  //   meta: {
  //     title: '知识库管理',
  //     icon: 'el-icon-zhishikuguanli',
  //   },
  //   children: [
  //     {
  //       path: '/knowledge/index',
  //       name: 'knowledgeList',
  //       component: () => import('@/views/knowledge/index'),
  //       meta: { title: '知识库', icon: 'el-icon-zhishikuguanli'}
  //     },
  //   ]
  // },
]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  // console.error(to, from, '=== created == beforeEach ===');
  const matcheds = to.matched||[];
  const {name} = matcheds[0]||{};
  const otherRouterNames = [
    'AccountInfo',
    'NoticeIndex',
    'MessageIndex',
    'Test'
  ];
  if(otherRouterNames.includes(to.name)){
    to.meta.activeMenu = from.meta.activeMenu||from.path||to.meta.activeMenu;
  }
  if (to.name != 'login' && !localStorage.getItem("token")) {
    next({
      name: 'login'
    })
  } else {
    const { menu } = store.state;
    const { width=0, mini=true } = Vue.prototype.$config;
    if(!mini){
      sessionStorage.setItem('collapse', true);
      store.dispatch('setSideBar', true);
    }
    if (!otherRouterNames.includes(to.name) && to.name != 'login' && menu && !hasValue(menu, to.path)) {
      next('/home');
      // Message.error({
      //   message:"暂无权限访问！",
      // });
      return false;
    }
    next();
  }
})

const hasValue = (arr=[], target='') =>{
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (item.uri === target) {
      return true;
    } else if (Array.isArray(item.childs)) {
      if (hasValue(item.childs, target)) {
        return true;
      }
    }
  }
  return false;
}

export default router
