<template>
  <div class="custom-dialog-header">
    <div class="custom-dialog-title" v-html="title"></div>
    <button type="button" class="fullscreen-btn" @click="toggleFullscreen">
      <i class="fullscreen-icon el-icon" :class="fullscreenIcon"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Test',
  props: {
    title: {
      type: String,
      default: ''
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  // 计算属性
  computed: {
    isFullscreen: {
      set (val) {
        this.$emit('update:fullscreen', val);
      },
      get () {
        return this.fullscreen;
      },
    },
    fullscreenIcon () {
      return this.isFullscreen ? 'el-icon-copy-document' : 'el-icon-full-screen'
    }
  },
  // 方法
  methods: {
    toggleFullscreen () {
      this.isFullscreen = !this.isFullscreen
    },
  },
  // 组件销毁
  beforeDestroy () {
  },
  // 监听
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.custom-dialog-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  .custom-dialog-title {
    line-height: 36px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    b {
      font-size: 13px;
      font-weight: 700;
      color: #f56c6c;
    }
  }
  .fullscreen-btn {
    padding: 0;
    border: none;
    background: transparent;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    i {
      font-size: 16px;
      font-weight: 700;
      color: #909399;
      &:hover {
        color: #409eff;
      }
    }
  }
}
</style>
