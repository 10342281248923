import request from "../axios";

// 七牛云Token
export function getQiniuToken(params) {
  return request({
    url: "/communal/qiniu",
    method: "get",
    params,
  });
}

// 获取七牛云预览token
export function getQiniuUpload(params) {
  return request({
    url: "/communal/qiniu-upload",
    method: "get",
    params,
  });
}

// 获取阿里云临时授权
export function aliyunSample(params) {
  return request({
    url: "/communal/aliyun-sample",
    method: "get",
    params,
  });
}

// 获取菜单类型数据
export function getMenuType(params) {
  return request({
    url: "/communal/menutype",
    method: "get",
    params,
  });
}

// 获取省
export function getProvince(params) {
  return request({
    url: "/communal/province",
    method: "get",
    params,
  });
}

// 获取市
export function getCity(params) {
  return request({
    url: "/communal/city",
    method: "get",
    params,
  });
}

// 获取区
export function getArea(params) {
  return request({
    url: "/communal/area",
    method: "get",
    params,
  });
}

// 获取部门及下级人员
export function getDeptUser(params) {
  return request({
    url: "/communal/department-user",
    method: "get",
    params,
  });
}

// 获取公司员工
export function getStaffUser(params) {
  return request({
    url: "/communal/user",
    method: "get",
    params,
  });
}

// 获取事件编号
export function getApplyCode(params) {
  return request({
    url: "/communal/code",
    method: "get",
    params,
  });
}

// 获取学习计划部门
export function getStudyDept(params) {
  return request({
    url: "/communal/department-study",
    method: "get",
    params,
  });
}

// 获取学习形式
export function getStudyShape(params) {
  return request({
    url: "/communal/study-shape",
    method: "get",
    params,
  });
}

// 获取项目执行后的优秀成果资源类型
export function getResourceType(params) {
  return request({
    url: "/communal/resource-type",
    method: "get",
    params,
  });
}
