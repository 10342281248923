import Dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween'

// 格式化dictCodeToName
export const dictCodeToName = (code='', arr=[]) => {
  // console.error(code,arr,'=== dictCodeToName ===');
  if (code && arr && arr.length) {
    const {label='', val='', full_name='', name='', title=''} = arr.find(({id,key,school_id}) => id == code||key == code || school_id == code)||{};
    return label || val || full_name || name || title || '';
  }
  return '';
}

// code To name
export const codeToName = (code, arr=[]) => {
  if (arr && arr.length) {
    if(typeof code == 'object'&&code.length){
      const nameArr = code.map(item => dictCodeToName(item, arr));
      return nameArr.filter(Boolean).join('、');
    }else if (objectType(code)) {
      const nameArr = JSON.parse(code).map(item => dictCodeToName(item, arr));
      return nameArr.filter(Boolean).join('、');
    } else {
      return dictCodeToName(code,arr);
    }
  }
  return '';
}

// 判断的是否是JSON字符串
export const objectType = (str) => {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      // 等于这个条件说明就是JSON字符串 会返回true
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        //不是就返回false
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
}

// 数字转为中文数字
export const numberToChinese = (number)=> {
  const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const chineseUnits = ['', '十', '百', '千', '万', '亿'];

  let result = '';
  let numStr = number.toString();
  for (let i = 0; i < numStr.length; i++) {
    let digit = parseInt(numStr[i]);
    let unit = chineseUnits[numStr.length - i - 1];
    if (digit === 0) {
      if (result[result.length - 1] !== '零') {
        result += chineseNumbers[digit];
      }
    } else {
      result += chineseNumbers[digit] + unit;
    }
  }
  return result;
}

// 当前时间与目标时间之前
export const checkDayisBefore = (targetTime) => {
  return Dayjs().isBefore(Dayjs(targetTime));
}

// 当前时间与目标时间之后
export const checkDayisAfter = (targetTime) => {
  return Dayjs().isAfter(Dayjs(Dayjs(targetTime).add(1,'day')));
}

// 当前时间加上差值如果大于目标时间则是过期
export const checkTimeisExpired = (diff,targetTime)=>{
  // 获取当前时间  
  const now = Dayjs();
  // 将差值(分钟)添加到当前时间【minute (分钟)】
  const timeAfterDiffMinutes = now.add(diff, 'minute');
  // 将给定的时间戳转换为 Day.js 对象  
  const givenTime = Dayjs(targetTime);
  return timeAfterDiffMinutes.isAfter(givenTime) || timeAfterDiffMinutes.isSame(givenTime); 
}

// 当前时间是否介于两个时间之间
export const checkIsBetween = (begin,end) => {
  if(begin&&end){
    Dayjs.extend(isBetween);
    return Dayjs().isBetween(Dayjs(begin), Dayjs(end).add(1,'day'));
  }
  return false
}

// 处理数字到最近的整数(默认保留两位小数)
export const toNearestInteger = (num=0, decimalPlaces=2, mode='round')=>{
  const factor = Math.pow(10, decimalPlaces);  
  if (mode === 'round') {  
    // 四舍五入到最近的整数
    return Math.round(num * factor) / factor;  
  } else if (mode === 'floor') {  
    // 向下取整到最近的整数
    return Math.floor(num * factor) / factor;  
  }
}

// 数字转换人民币
export const formatCNY = (num=0, style='decimal')=>{  
  const formatter = new Intl.NumberFormat('zh-CN', {  
    style,  // decimal (十进制) currency（货币）percent（百分比）。默认值为decimal
    currency: 'CNY',
    // currencyDisplay:'symbol',
    minimumFractionDigits: 2, // 最小小数位数  
    maximumFractionDigits: 2 // 最大小数位数  
  });
  return formatter.format(num);
}

// 格式化菜单类型
export const formatMenuType = (n)=> {
  let type = '';
  switch(n){
    case 1: // 目录
      type='warning'
      break;
    case 2: // 菜单
      type='success'
      break;
    case 3: // 按钮
      type='danger'
      break;
    case 4: // 外链
      type='primary'
      break;
    case 5: // 页面
      type='info'
      break;
    default:
  }
  return type;
}

// 文件字节大小（返回带单位值）
export const formatBytes = (bytes) => {
  if (bytes >= 1024 * 1024 * 1024) {
    return toNearestInteger(bytes / (1024 * 1024 * 1024)) + 'GB';
  } else if (bytes >= 1024 * 1024) {
    return toNearestInteger(bytes / (1024 * 1024)) + 'MB';
  } else if (bytes >= 1024 ) {
    return toNearestInteger(bytes / 1024) + 'KB';
  } else {
    return bytes + 'B';
  }
}

// url 拼接参数
export const appendQueryParams = (url='', params='') => {
  // 如果url不是 blob 开头的则拼接参数
  if(!/^blob:/.test(url)){
    const mark = url.includes('?') ? '&' : '?';
    // 如果params是字符串，直接拼接  
    if (typeof params === 'string') {  
      return `${url}${mark}${params}`;  
    }  
    // 如果params是对象，遍历并拼接  
    if (typeof params === 'object') {  
      const queryParams = Object.entries(params).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');  
      return `${url}${mark}${queryParams}`;  
    }
  }else{
    return url;
  }
}

// 提取URL中文件信息
export const extractFileInfoFromURL = (url='')=> {
  if (url.startsWith('http')) {
    const { pathname } = new URL(url);
    url = pathname;
  }
  // 正则表达式匹配文件目录和文件名  
  const result = url.match(/^(.*?\/)([^\/]+)$/);
  // result[1] 是目录（包括第一个斜杠），result[2] 是文件名（如果存在）  
  // 如果没有文件名，result[2] 将是undefined  
  // 注意：如果URL没有目录（即直接指向根目录），result[1] 将是 '/'  
  const directory = result ? (result[1] === '/' ? '' : result[1]) : '';  
  const filename = result ? result[2] || '' : '';  
  // 匹配文件后缀
  const suffixMatch = filename.match(/(\.[^.]+)$/);
  const filetype = suffixMatch ? suffixMatch[1] : '';
  return { directory, filename, filetype };
}

// 确保数组对象
export const ensureArray = (value) => {
  if (value&&Array.isArray(value)) {
    return value;
  } else if (value&&Object.keys(value).length) {
    return [value]
  } else {
    return []
  }
}

// 按钮状态
export const buttonState = ({key}) => {
  if(key==1){
    return {
      icon:'el-icon-tongguo',
      type:'success',
    }
  }else if(key==2){
    return {
      icon:'el-icon-butongguo',
      type:'danger',
    }
  }else if(key==3){
    return {
      icon:'el-icon-huitui',
      type:'primary',
    }
  }else{
    return {
      icon:'',
      type:'',
    }
  }
}